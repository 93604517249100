import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { CircularProgress, FormControl, Input, InputLabel } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "../../ui/ErrorMessage";
import { getNevLink } from "../../../utils/getFileFromUrl";

interface UserProps {
  formik: any;
  pageStatus: any;

}

export const AddClientForm: React.FC<UserProps> = ({
  formik,pageStatus
}) => {
  let navigate = useNavigate();
 
  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12} sm ={6} md={4}>
        <FormControl
          fullWidth
          variant="standard"
          required
          error={
            formik.touched.companyName &&
            Boolean(formik.errors.companyName)
          }
        >
          <InputLabel htmlFor="companyName">
            Name
          </InputLabel>
          <Input
            id="companyName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values?.companyName}
            name="companyName"
            type="text"
            inputProps={{ "data-testid": "input-client-name" }}
          />
          <ErrorMessage errorText={formik.touched.companyName && formik.errors.companyName} />
        </FormControl>
        </Grid>
        <Grid item xs={12} sm ={6} md={4}>
        <FormControl
          fullWidth
          required
          error={
            formik.touched.email &&
            Boolean(formik.errors.email)
          } 
        >
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values?.email}
            name="email"
            type="email"
            inputProps={{ "data-testid": "input-client-email" }}
          />
          <ErrorMessage errorText={formik.touched.email && formik.errors.email} />
        </FormControl>
        </Grid>
        <Grid item xs={12} sm ={6} md={4}>
        <FormControl
          fullWidth
          variant="standard"
          required
          error={
            formik.touched.companyWebsite &&
            Boolean(formik.errors.companyWebsite)
          }
        >
          <InputLabel htmlFor="companyWebsite">
            Website
          </InputLabel>
          <Input
            id="companyWebsite"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values?.companyWebsite}
            name="companyWebsite"
            type="text"
            inputProps={{ "data-testid": "input-client-website" }}
          />
          <ErrorMessage errorText={formik.touched.companyWebsite && formik.errors.companyWebsite} />
        </FormControl>
        </Grid>

      <Grid item xs={12}>
          <Box
            mt={3}
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              variant="text"
              className="btn-cancel"
              style={{ marginRight: "15px",textTransform:"none" }}
              onClick={() => {
                navigate(getNevLink("/admin/clients"));
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={pageStatus.loading}
              endIcon={ pageStatus.loading && 
                <CircularProgress
                  size={"16px"}
                  color={"inherit"}
                />}
              onClick={() => {
                console.log(formik.errors);
                formik.handleSubmit();
              }}
              style={{textTransform:'none'}}
            >
              Send Verification Link
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
