import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
 
import { GetChangeTracker } from "../../../Services/invoiceManagerService";
import { CommissionTypes } from "../../../models/interfaces";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { CheckBoxSquareIcon } from "../../Common/CheckBoxSquareIcon";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
 
const useStyles = makeStyles((theme) => ({
  menuItem: {
    "& .MuiMenu-list li:hover": {
      backgroundColor: "#fff !important",
      color: "#4540DB",
    },
  },
}));
 
interface Props {
  selectedRecruiter: any;
  key: any;
}
 
export const ChangeTracker: React.FC<Props> = (props) => {
  const classes = useStyles();
 
  const initialTrackerFilterState = {
    compensationTypes: [],
    recruiterGuid:
      props.selectedRecruiter == null ? "" : props.selectedRecruiter?.id,
    trackerListType:
      props.selectedRecruiter == null ? "AllRecruiter" : "IndividualRecruiter",
  };
 
  const [filter, setfilter] = useState(initialTrackerFilterState);
  const [loading, setLoading] = useState(false);
  const [changeTrackerdata, setchangeTrackerData] = useState<any>([]);
 
  const ClearAllActive = () => {
    setfilter(initialTrackerFilterState);
  };
 
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl2);
 
  const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
 
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
 
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setfilter({
        ...filter,
        compensationTypes: [...filter.compensationTypes, e.target.name],
      });
    } else {
      let filteredArray = filter.compensationTypes.filter(
        (item) => item !== e.target.name
      );
 
      setfilter({
        ...filter,
        compensationTypes: filteredArray,
      });
    }
  };
 
  const changeTrackerAPI = () => {
    setchangeTrackerData([]);
    setLoading(true);
    GetChangeTracker(filter)
      ?.then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
         
          setchangeTrackerData(response?.data?.entityList);
        } else {
          setchangeTrackerData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  useEffect(() => {
    changeTrackerAPI();
  }, [filter.compensationTypes, filter.recruiterGuid]);
 
  useEffect(() => {
    setfilter({
      ...filter,
      recruiterGuid:
        props.selectedRecruiter == null ? "" : props.selectedRecruiter?.id,
      trackerListType:
        props.selectedRecruiter == null
          ? "AllRecruiter"
          : "IndividualRecruiter",
    });
  }, [props?.selectedRecruiter]);
 
  function formatContractType(type: string) {
    if (type === "Direct Contract/C2C") return "Contract";
    else if (type === "Contract (W2)") return "Contract";
    else return type;
  }
 
  function formatEmployment(emp: string) {
    if (emp === "1099") return "C2C";
    else if (emp === "Full Time") return "NA";
    else if (emp === "") return "NA";
    else return emp;
  }
 
  return (
    <React.Fragment>
      <br />
 
      <Card sx={{ maxWidth: "100%" }}>
        <CardContent sx={{ marginTop: "10px" }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={6}>
              <Typography
                variant="h6"
                component="span"
                className="letter-spacing-normal"
              >
                Change Tracker
              </Typography>
            </Grid>
            <Grid style={{ textAlign: "end" }} item xs={6} md={6}>
              <Button
                variant="text"
                component="div"
                size="large"
                className="v2-base-btn cust-btn-bgClr searchbarRow-ht-btn toolbarActionBtn"
                startIcon={<FilterAltOutlinedIcon />}
                id="demo-positioned-button"
                aria-controls={open2 ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                onClick={handleClick2}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
          <Item elevation={0}>
            <Typography
              variant="h4"
              component="div"
              className="abel letter-spacing-normal primary-color-text heading-underline typography-with-right-text"
              gutterBottom
              style={{ marginLeft: "-24px" }}
            ></Typography>
          </Item>
 
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl2}
            open={open2}
            className={classes.menuItem}
            onClose={handleClose2}
          >
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "#FAFAFA",
                display: "flex",
                marginTop: "-8px",
              }}
            >
              <Grid container p={2}>
                <Grid item md={6}>
                  <Typography fontWeight={"bold"} className="roboto-font">
                    Filters
                  </Typography>
                </Grid>
                <Grid item xs={6} className="closeArea">
                  <IconButton
                    aria-label="close"
                    size="small"
                    className="close-Btn"
                    onClick={handleClose2}
                    data-testid="onCloseIcon"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "white",
                display: "flex",
                maxHeight: 386,
                overflowY: "auto",
              }}
            >
              <Grid
                container
                style={{ width: 223, paddingBottom: "0px" }}
                p={2}
              >
                <Grid item md={12}>
                  <Grid container>
                    <Grid item pt={1} pb={0.5}>
                      <FormControlLabel
                        data-testid="clearAllBtn"
                        onClick={() => {
                          ClearAllActive();
                        }}
                        control={
                          <CheckBoxSquareIcon
                            style={{ paddingRight: "5px" }}
                          ></CheckBoxSquareIcon>
                        }
                        label={
                          <Typography fontSize={14} className="roboto-font">
                            Clear All
                          </Typography>
                        }
                        className="candidates-label"
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          paddingLeft: 0,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{ maxHeight: 300, overflowY: "auto" }}
                >
                  <MenuList style={{ paddingTop: "0px" }}>
                    <MenuItem
                      sx={{
                        paddingLeft: "0px",
                        paddingBottom: "0px",
                        minHeight: "auto",
                        marginBottom: "-12px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => handleFilterChange(e)}
                            name={CommissionTypes.DirectContract}
                            checked={filter.compensationTypes.includes(
                              CommissionTypes.DirectContract
                            )}
                          />
                        }
                        label="1099"
                      />
                    </MenuItem>
                    <MenuItem
                      sx={{
                        paddingLeft: "0px",
                        paddingBottom: "0px",
                        minHeight: "auto",
                        marginBottom: "-12px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => handleFilterChange(e)}
                            name={CommissionTypes.W2_Employee}
                            checked={filter.compensationTypes.includes(
                              CommissionTypes.W2_Employee
                            )}
                          />
                        }
                        label="W2"
                      />
                    </MenuItem>
                    <MenuItem
                      sx={{
                        paddingLeft: "0px",
                        paddingBottom: "0px",
                        minHeight: "auto",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => handleFilterChange(e)}
                            name={CommissionTypes.FullTime}
                            checked={filter.compensationTypes.includes(
                              CommissionTypes.FullTime
                            )}
                          />
                        }
                        label="Full Time"
                      />
                    </MenuItem>
                  </MenuList>
                </Grid>
              </Grid>
            </Box>
          </Menu>
 
          <TableContainer
            style={{
              height:
                changeTrackerdata?.length > 4
                  ? "300px"
                  : "200px",
              marginLeft: "-16px",
            }}
            component={Paper}
            className=""
            elevation={0}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Job Type</TableCell>
                  <TableCell align="left">Tax Terms</TableCell>
                  <TableCell align="left">Percentage(%)</TableCell>
                  <TableCell align="left">Payroll Overhead(%)</TableCell>
                  <TableCell align="left">OTC(USD)</TableCell>
                  <TableCell align="left">Effective Date</TableCell>
                  <TableCell align="left">Email Address</TableCell>
                </TableRow>
              </TableHead>
 
              <TableBody>
                {loading && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      Please wait...
                    </TableCell>
                  </TableRow>
                )}
                {!loading &&
                  changeTrackerdata?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No Records Found
                      </TableCell>
                    </TableRow>
                  )}
                {changeTrackerdata?.map((x: any) => {
                  return (
                    <TableRow
                      key={x?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {formatContractType(x.type)}
                      </TableCell>
                      <TableCell align="left">
                        {formatEmployment(x.employment)}
                      </TableCell>
                      <TableCell align="left">{x.percentage}</TableCell>
                      <TableCell align="left">{x.payrollOverhead}</TableCell>
                      <TableCell>
                        {" "}
                        {x?.oneTimeCommission <= 0
                          ? "NA"
                          : x?.oneTimeCommission || "NA"}
                      </TableCell>
                      <TableCell align="left">{x.effectiveDate}</TableCell>
                      <TableCell align="left">{x.emailAddress}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};
