import {
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  makeStyles,
} from "@material-ui/core";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { CircularProgress, Slider } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  GetAutoSuggessionJobAndClientName,
  GetFindJob,
  getJobWorkAuthorization,
  getJobWorkExperience,
  getParseResume,
  getScoreCard
} from "../../../Services/jobService";
import { GetAllCurrencyList } from "../../../Services/masterCurrencyService.js";
import { defaultDataAutocomplete } from "../../../data/defaultDataAutocomplete";
import { resetFindJobsSearchState, setFindJobsResultState, setFindJobsSearchState } from "../../../features/Jobs/FindJobsSearchSlice";
import { useNavigatingAway } from "../../../hooks/useNavigatingAway";
import { BreadCrumbItem } from "../../../models/AppModels";
import getAddressForAutoComplete from "../../../utils/getAddressForAutoComplete";
import getLabel from "../../../utils/getLabelForAutoComplete";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { Asterisk } from "../../ui/Asterisk";
import { ErrorMessage } from "../../ui/ErrorMessage";
import { IndustryAutocomplete } from "../../ui/IndustryAutocomplete";
import { DialogLeavingPage } from "./DialogLeavingPopup";
import RecommendJobScorecardDialog from "./RecommendJobScorecardDialog";
import { RecommendedJobsSearchRow } from "./RecommendedJobsSearchRow";
import { valueOrDefault } from "../../../utils/complexityUtils";
import { getNevLink } from "../../../utils/getFileFromUrl";
import { SortType } from "../../../models/app/AppModels";
import { sortByProperty } from "../../../utils/randomUtils";
import SectionHeading from "../../ui/Form/Heading/SectionHeading";

interface IPROPS {}

const useStyles = makeStyles({
  option: {
    padding: "0px",
    margin: "1px !important",
  },
});

interface SliderState {
  [key: string]: number;
}

const FindJob: React.FunctionComponent<IPROPS> = (props) => {
  const findJobsSearchStateRTK = useSelector(
    (state: any) => state.findJobsSearchSlice
  );

  let defaultBreadcrumb: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: true, sequence: 3, title: "Find Jobs" },
  ];
  const dispatch = useDispatch()
  const classes = useStyles();
  const [sliderValues, setSliderValues] = React.useState<SliderState>(findJobsSearchStateRTK.sliderInfo);
  const [isUploadTouch, setIsUploadTouch] = useState(false);
  const [jobTitleSearchOptionList, setJobTitleSearchOptionList] = useState<
    any[]
  >([]);
  const [jobTitleOption, setJobTitleOption] = useState([]);
  const [searchAutoSuggestJobtitleText, setSearchAutoSuggestJobtitleText] =
    useState("");
  const [activeAutoSuggession, setActiveAutoSuggession] = useState("");
  const [masterCurrercyList, setMasterCurrencyList] = useState([]);
  const [autoCompleteSuggesions, setautoCompleteSuggesions] = useState<any[]>(
    []
  );
  const [locationSearchOptionList, setLocationSearchOptionList] = useState<
    any[]
  >([]);
  const [recommendedJobDataList, setRecommendedJobDataList] = useState<any[] | undefined>(findJobsSearchStateRTK?.results);
  const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);
  const [loading] = useState(false);
  const [isParsingResume, setIsParsingResume] = useState(false);
  const [totalRecords, setTotalRecords] = useState(findJobsSearchStateRTK?.results?.length ?? 0);
  const [hasMoreData] = useState<any>(null);
  const [resumeFile, setResumeFile] = useState<{
    file: File;
    fileName: string;
  }>();
  const [isNewResumeUpload, setIsNewResumeUpload] = useState<boolean>(false);
  const [canShowDialogLeavingPage, setShowDialogLeavingPage] =
    useState<boolean>(false);
  const [scorecardPopup, setScorecardPopup] = React.useState<{
    jobData?: any;
    isLoading?: boolean;
    display?: boolean;
    email?: string;
    selectedRowJobDetails?:any
  }>();

  const [sort, setSort] = React.useState<{
    sortBy: string;
    sortOrder: SortType;
  }>({ sortBy: "eximScore", sortOrder: SortType.Descending });

  const [jobWorkExperiencesList, setJobWorkExperiencesList] = useState<any[]>(
    []
  );
  const [jobWorkAuthorizationList, setJobWorkAuthorizationList] = useState<
    string[]
  >([]);

  const [scoreResumeStatus, setScoreResumeStatus] =
  React.useState<{statusCode?:number, statusMsg?:string}>();

  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const formik = useFormik({
    initialValues: findJobsSearchStateRTK,
    validationSchema: UserValidtionSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    window.addEventListener("beforeunload", function (event) {
      event.stopImmediatePropagation();
    });
    getAllMasterCurrencyList();


    _GetJobWorkAuthorization();
    _GetJobWorkExperiencesList();
  }, []);

  useEffect(() => {
    if (
      formik.values.currency !== "" ||
      formik.values.domains.length > 0 ||
      formik.values.industries.length > 0 ||
      formik.values.jobType.length > 0 ||
      formik.values.minimumPayRate !== "" ||
      formik.values.maximumPayRate !== "" ||
      (formik.values.resume !== "" && formik.dirty)
    ) {
      if (!findJobsSearchStateRTK?.results) setShowDialogLeavingPage(true);
    }
  }, [
    formik.values.currency,
    formik.values.domains,
    formik.values.industries,
    formik.values.jobType,
    formik.values.minimumPayRate,
    formik.values.maximumPayRate,
    formik.values.resume,
  ]);

  useEffect(() => {
    if (resumeFile?.fileName !== "") {
      formik.setFieldValue("resumeFile", resumeFile);
    }
  }, [resumeFile]);

  // call autosuggesstion for jobTitle field
  useEffect(() => {
    setActiveAutoSuggession("jobTitle");
    GetAutoSuggessionJobAndClient({
      jobTitle: searchAutoSuggestJobtitleText,
      client: "",
    });
  }, [searchAutoSuggestJobtitleText]);

  const handleReset = () => {
    setShowDialogLeavingPage(false);
    formik.setFieldValue("resume", "");
    formik.setFieldValue("resumeInfo", {
      resourceUrl: "",
      fileName: "",
      newFileName: "",
    });
    formik.setFieldValue("jobType", []);
    formik.setFieldValue("domains", []);
    formik.setFieldValue("industries", []);
    formik.setFieldValue("jobTitle", []);
    formik.setFieldValue("location", []);
    formik.setFieldValue("minimumPayRate", "");
    formik.setFieldValue("maximumPayRate", "");
    formik.setFieldValue("currency", "");
    formik.setFieldValue("searchRadius", "");
    formik.setFieldValue("remote", "");
    formik.setFieldValue("workExp", "");
    formik.setFieldValue("experienceId", "");
    formik.setFieldValue("experienceText", "");
    formik.setFieldValue("workAuthorizations", []);
    formik.setFieldValue("n", "10");
    formik.setFieldValue("resumeFile", {
      file: File,
      fileName: String,
    });
    formik.setFieldValue("isSearchResult", false);
    setautoCompleteSuggesions([]);
    setJobTitleSearchOptionList([]);
    setLocationSearchOptionList([]);
    setRecommendedJobDataList(undefined)
    dispatch(resetFindJobsSearchState())
    formik.setFieldTouched("resume", false);
    formik.setFieldTouched("domains", false);
    formik.setFieldTouched("jobTitle", false);
    setSliderValues({
      skills_pct: 14.2,
      job_title_pct: 14.2,
      technology_pct: 14.2,
      tools_pct: 14.2,
      education_pct: 14.2,
      certification_pct: 14.2,
      industry_pct: 14.2,
    })
    setTotalRecords(0);
  };


  const getAllMasterCurrencyList = () => {
    GetAllCurrencyList()
      .then((response) => {
        let currencyList = response.data.entityList.map(
          (item: any, index: any) => item
        );
        setMasterCurrencyList(currencyList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetAutoSuggessionJobAndClient = (SearchData: any) => {
    GetAutoSuggessionJobAndClientName(SearchData)
      .then((response: any) => {
        if (activeAutoSuggession === "jobTitle") {
          setJobTitleOption(response.data.entity.suggestion);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (values: any) => {
    let formData = new FormData();
    formData.append("PayCurrency", values?.currency);
    formData.append("MinPayrate", values?.minimumPayRate);
    formData.append("MaxPayrate", values?.maximumPayRate);
    formData.append("JobTitle", jobTitleSearchOptionList[0]);
    if (values?.location && values?.location.length > 0) {
      formData.append(
        "Location",
        values?.location[0]?.location?.city +
          ", " +
          values?.location[0]?.location?.state +
          ", " +
          values?.location[0]?.location?.country
      );
    } else {
      formData.append("Location", "");
    }

    values?.industries.forEach((item: any) =>
      formData.append("Industry[]", item.industry)
    );
    values?.domains.forEach((item: any) => formData.append("Domains[]", item));
    values?.jobType.forEach((item: any) => formData.append("JobType[]", item));
    values?.workAuthorizations.forEach((item: any) => formData.append("WorkAuth[]", item));

    formData.append("N", values?.n);
    formData.append("PayType", "");
    formData.append("SearchRadius", values?.searchRadius ?? "");
    formData.append("WorkExp", values?.workExp);
    formData.append("AllowRelocation", values?.allowRelocation);
    formData.append("Remote", values?.remote);

    if (isNewResumeUpload) {
      formData.append("File", formik.values?.resumeFile?.file as File);
    } else {
      formData.append("File", findJobsSearchStateRTK.resumeFile?.file as File);
    }

    formData.append("skillsPct", sliderValues?.skills_pct.toString());
    formData.append("jobTitlePct", sliderValues?.job_title_pct.toString());
    formData.append(
      "technologyPct",
      sliderValues?.technology_pct.toString()
    );
    formData.append("toolsPct", sliderValues?.tools_pct.toString());
    formData.append("educationPct", sliderValues?.education_pct.toString());
    formData.append(
      "certificationPct",
      sliderValues?.certification_pct.toString()
    );
    formData.append("industryPct", sliderValues?.industry_pct.toString());


    getFindJobAPI(formData);
    localStorage.setItem(
      "findjobsSearchCriteria",
      JSON.stringify({ ...values, jobTitle: jobTitleSearchOptionList })
    );
  };

  const getFindJobAPI = (model: any) => {
    setIsFormSubmitting(true);
    GetFindJob(model)
      .then((response: any) => {
        setShowDialogLeavingPage(false);
        let results = sortByProperty(response?.data?.entity?.messageList || [], sort.sortBy, sort.sortOrder);
        setRecommendedJobDataList(results);
        dispatch(setFindJobsSearchState({...formik.values, sliderInfo:sliderValues}))
        dispatch(setFindJobsResultState(results))
        setIsFormSubmitting(false);
        setTotalRecords(response.data?.totalRecords);
      })
      .catch((error: any) => {
        setIsFormSubmitting(false);
      })
      .finally(() => {
        setShowDialogLeavingPage(false);
      });
  };

  const onResumeFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsUploadTouch(true);
    if (e.currentTarget.files) {
      if (!isValidFileExtention(e.currentTarget.files[0].name)) {
        formik?.setFieldError("resume", "Invalid file extention");
      } else if (e.currentTarget.files[0].size > 2000000) {
        formik?.setFieldError("resume", "File size too large");
      } else {
        handleUploadClick(e.currentTarget.files || null, "Resume");
        formik?.setFieldValue(
          "resume",
          e.currentTarget.files ? e.currentTarget.files[0].name : ""
        );
        _GetParseResume(e.currentTarget.files[0]);
      }
    }
  };

  const handleUploadClick = (files: FileList | null, type: string) => {
    if (!files) return;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    setIsNewResumeUpload(true);
    if (type === "Resume") {
      formik.setFieldValue("resumeFile", {
        file: files[0],
        fileName: files[0].name,
      });

      setResumeFile({
        file: files[0],
        fileName: files[0].name,
      });
    }
  };

  const handleIndustryChange = (e: any, value: any) => {
    formik?.setFieldValue("industries", value);
  };

  const renderPreferredGeographies = ({ getInputProps, suggestions }: any) => (
    <Autocomplete
      className="findjobs-chips"
      multiple
      id="size-small-standard-multi1"
      size="medium"
      onKeyPress={(e) => {
        formik.values.location?.length === 5
          ? e.preventDefault()
          : console.log();
      }}
      onChange={(e, value: any) => {
        formik.setFieldValue("location", value);
        setLocationSearchOptionList(value);
      }}
      options={
        suggestions.length > 0
          ? suggestions.map((str: any) => ({
              id: str.placeId,
              location: {
                city: getAddressForAutoComplete(str.description).city,
                state: getAddressForAutoComplete(str.description).state,
                country: getAddressForAutoComplete(str.description).country,
              },
            }))
          : defaultDataAutocomplete
      }
      getOptionDisabled={(option: any) =>
        formik.values.location.some((e: any) => e.id === option.id) ||
        formik.values.location?.length === 5
          ? true
          : false
      }
      value={locationSearchOptionList}
      getOptionLabel={(option: any) =>
        getLabel(option.location.city, option.location.state)
      }
      renderTags={(value, getTagProps) =>
        value?.map((option: any, index: any) => (
          <Chip
            color="primary"
            size="small"
            label={getLabel(option.location.city, option.location.state)}
            {...getTagProps({ index })}
          />
        ))
      }
      onBlur={(e: any) => {
        if (e.target.value && e.target.value !== "") {
          setLocationSearchOptionList([
            ...locationSearchOptionList,
            e.target.value,
          ]);
        }
      }}
      clearOnBlur
      renderInput={(params) => (
        <TextField
          data-testid="cities-autosuggestion"
          {...params}
          {...getInputProps()}
          variant="standard"
          label=""
        />
      )}
    />
  );

  // GET Master GetJobWorkAuthorization
  const _GetJobWorkAuthorization = async () => {
    getJobWorkAuthorization()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobWorkAuthorizationList(response?.data?.entityList);
        } else {
          setJobWorkAuthorizationList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get Master Job work experiences
  const _GetJobWorkExperiencesList = () => {
    getJobWorkExperience()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobWorkExperiencesList(response?.data?.entityList);
        } else {
          setJobWorkExperiencesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getExperienceLabel = (id: string) => {
    const jobExperience: any = jobWorkExperiencesList?.find(
      (item: any) => item.id === id
    );
    return valueOrDefault(jobExperience?.experience, "");
  };

  const _GetParseResume = async (resumeFile: any) => {
    let formData = new FormData();
    formData.append("file", resumeFile);
    setIsParsingResume(true);
    getParseResume(formData).then((res)=>{
      if(res.data?.status === 200){
        let resume = res.data?.entity?.resumeRecord;
        let info = resume?.parsedUserInfo;
        formik.setFieldValue("domains",[info?.domain[0]])
        formik.setFieldValue("jobTitle",[info?.candidatePersonalInfo?.jobPosition[0]])
        setJobTitleSearchOptionList([info?.candidatePersonalInfo?.jobPosition[0]])
        let address = info?.candidatePersonalInfo?.address;
        formik.setFieldValue("location", [{location:address}]);
        setLocationSearchOptionList( [{location:address}])
        let experience = jobWorkExperiencesList.find(n=>n.experienceValue === info?.professionalExperience?.yearsOfexperienceInNumbers);
        if(experience){
          formik.setFieldValue("workExp", experience?.experienceValue);
          formik.setFieldValue("experienceId", experience?.id);
          formik.setFieldValue("experienceText", experience?.experience);
        }
      }
    }).finally(()=>{
      setIsParsingResume(false);
    })
  }

  const handleSliderChange = (key: string, value: number) => {
    const updatedValues = { ...sliderValues, [key]: value };
  
    // Calculate total value of all sliders excluding the current one
    const totalOtherSliders = Object.values(updatedValues).reduce(
      (acc, curr, currIndex) => {
        if (Object.keys(updatedValues)[currIndex] !== key) {
          return acc + curr;
        }
        return acc;
      },
      0
    );
    const remainingPercentage = 100 - value;
    // If all other sliders are zero, distribute the value equally
    if (totalOtherSliders === 0) {
      const numOtherSliders = Object.keys(updatedValues).length - 1;
      for (const [k] of Object.entries(updatedValues)) {
        if (k !== key) {
          updatedValues[k] = parseFloat((remainingPercentage / numOtherSliders).toFixed(2));
        }
      }
    } else {
      // Distribute remaining percentage proportionally
      for (const [k] of Object.entries(updatedValues)) {
        if (k !== key) {
          updatedValues[k] = parseFloat(
            ((updatedValues[k] / totalOtherSliders) * (100 - value)).toFixed(2)
          );
        }
      }
    }
  
    setSliderValues(updatedValues);
  };

  const handleViewScoreCard = (n:any) =>{
    
    let formData = new FormData();
    if (isNewResumeUpload) {
      formData.append("file", resumeFile?.file as File);
    } else {
      formData.append("file", findJobsSearchStateRTK.resumeFile?.file as File);
    }
    
    formData.append("skillsPct", sliderValues?.skills_pct.toString());
    formData.append("jobTitlePct", sliderValues?.job_title_pct.toString());
    formData.append("technologyPct", sliderValues?.technology_pct.toString());
    formData.append("toolsPct", sliderValues?.tools_pct.toString());
    formData.append("educationPct", sliderValues?.education_pct.toString());
    formData.append(
      "certificationPct",
      sliderValues?.certification_pct.toString()
    );
    formData.append("industryPct", sliderValues?.industry_pct.toString());
    formData.append("jobId", n?.jobData?.id.toString());
    formData.append("feature", "JobSearch");
    formData.append("eximScore", n?.eximScore);
    formData.append("jobData", JSON.stringify(n?.jobData));
    formData.append("resumeData", JSON.stringify(n?.resumeData));
    formData.append(
      "unweightedMatchedScores",
      JSON.stringify(n?.unweightedMatchedScores)
    );
    setScorecardPopup({...scorecardPopup, isLoading: true, jobId: n?.jobData?.id.toString() });

    getScoreCard(formData)
    .then((res) => {
      if (res.status !== 200) {
        setScorecardPopup({...scorecardPopup, isLoading: false });
        setScoreResumeStatus({
          statusCode:res.status,
          statusMsg: "Failed"
        })
      } else {
        
        setScorecardPopup({
          ...scorecardPopup,
          jobData: res.data?.entity,
          display: true,
          isLoading: false,
          selectedRowJobDetails:n
        });
        setScoreResumeStatus({
          statusCode:200,
          statusMsg: "Success"
        })
      }
    })
    .catch(() => {
      setScorecardPopup({ isLoading: false });
    });
  }

  const onSort = (sortBy: string) => {
    let sortOrderBy =
      sort?.sortOrder === SortType.Ascending
        ? SortType.Descending
        : SortType.Ascending;

    setSort({
      sortBy: sortBy,
      sortOrder: sortOrderBy,
    });
    let sortedData = sortByProperty(recommendedJobDataList ?? [], sortBy, sortOrderBy);

    setRecommendedJobDataList(sortedData);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <AppBreadCrumb
                classes="custom-appbreadcrumb"
                BreadCrumbItems={defaultBreadcrumb}
              ></AppBreadCrumb>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "400px",
                }}
              >
              Upload resume here to find suitable jobs
              </Typography>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={1} className="editRecruter-wt">
            <Grid item xs={12} md={12} lg={12}>
              <Paper elevation={1} sx={{ padding: 2 }}>
                <Box mt={{ xs: 1, sm: 2, md: 3 }}>
                  <Box className="form-upload-resume" mt={0} mb={1}>
                    <Grid item xs={12} md={12}>
                      <FormControl
                        variant="standard"
                        required
                        error={isUploadTouch && Boolean(formik.errors.resume)}
                      >
                        <InputLabel htmlFor="resume">
                          Resume <Asterisk />
                        </InputLabel>
                        <Input
                          name="resume"
                          type="text"
                          readOnly
                          value={formik.values.resume}
                        />
                        <ErrorMessage
                          errorText={
                            formik.touched?.resume && formik.errors?.resume
                          }
                        />
                      </FormControl>

                      <Button
                        variant="contained"
                        component="label"
                        className="upload-btn"
                        disabled={isParsingResume}
                        endIcon={
                          isParsingResume ? (
                            <CircularProgress size={"16px"} color={"inherit"} />
                          ) : (
                            ""
                          )
                        }
                      >
                        <FileUploadOutlinedIcon
                          style={{ marginRight: "5px", width: "20px" }}
                        />{" "}
                        Attach Resume
                        <input
                          data-testid={"uploadResumeId"}
                          type="file"
                          hidden
                          accept={".txt,.rtf,.doc,.docx,.pdf"}
                          onChange={onResumeFileChange}
                          onClick={(e) => {
                            e.currentTarget.value = "";
                          }}
                        />
                      </Button>
                      <Box
                        className="form-three-child"
                        sx={{ position: "relative", top: "0" }}
                      >
                        <Typography
                          variant="subtitle1"
                          component="div"
                          className=""
                        >
                          (Max file size: 2MB as
                          <span> .txt, .rtf, .doc, .docx, .pdf</span>)
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                </Box>
                <Grid container xs={12} md={12} spacing={2}>
                  <Grid item xs={6}>
                    <Typography>
                      {" "}
                      Select Domain <Asterisk />{" "}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="domains"
                          value={"IT"}
                          checked={formik.values?.domains?.some(
                            (x: any) => x === "IT"
                          )}
                          onChange={formik.handleChange}
                          size="small"
                          data-testid="domain-checkbox"
                        />
                      }
                      label="IT"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="domains"
                          value={"Non-IT"}
                          checked={formik.values?.domains?.some(
                            (x: any) => x === "Non-IT"
                          )}
                          onChange={formik.handleChange}
                          size="small"
                          data-testid="domain-checkbox"
                        />
                      }
                      label="Non-IT"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="domains"
                          value={"Healthcare"}
                          checked={formik.values?.domains?.some(
                            (x: any) => x === "Healthcare"
                          )}
                          onChange={formik.handleChange}
                          size="small"
                          data-testid="domain-checkbox"
                        />
                      }
                      label="Healthcare"
                    />
                    <ErrorMessage
                      errorText={
                        formik.touched?.domains && formik.errors?.domains
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="roboto-font" style={{ color: "black" }}>
                      {" "}
                      Type of Jobs{" "}
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="jobType"
                          value={"Full time"}
                          checked={formik.values?.jobType?.some(
                            (x: any) => x === "Full time"
                          )}
                          onChange={formik.handleChange}
                          size="small"
                          data-testid="jobtype-checkbox"
                        />
                      }
                      label="Full time"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="jobType"
                          value={"Contract"}
                          checked={formik.values?.jobType?.some(
                            (x: any) => x === "Contract"
                          )}
                          onChange={formik.handleChange}
                          size="small"
                          data-testid="jobtype-checkbox"
                        />
                      }
                      label="Contract"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="">Job Title</Typography>
                    <Autocomplete
                      multiple
                      className="findjobs-chips"
                      freeSolo
                      id="tags-standard"
                      disableClearable
                      options={jobTitleOption}
                      getOptionLabel={(option: any) => option}
                      classes={{
                        option: classes.option,
                      }}
                      onBlur={(e: any) => {
                        if (e.target.value !== "") {
                          setJobTitleSearchOptionList([
                            ...jobTitleSearchOptionList,
                            e.target.value,
                          ]);
                        }
                      }}
                      clearOnBlur
                      getOptionDisabled={() =>
                        jobTitleSearchOptionList.length >= 5 ? true : false
                      }
                      value={formik.values.jobTitle}
                      onChange={(event: any, newValue: any) => {
                        setJobTitleSearchOptionList(newValue);
                        formik.setFieldValue("jobTitle", newValue);
                      }}
                      onKeyUp={(event: any) =>
                        setSearchAutoSuggestJobtitleText(event.target.value)
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option: any, index: any) => (
                          <Chip
                            color="primary"
                            size="medium"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id="jobTitle"
                          name="jobTitle"
                          label=""
                          type="text"
                          inputProps={{
                            ...params.inputProps,
                            "data-testid": "autocomplete-jobtitle",
                            onKeyDown: (e) => {
                              if (
                                jobTitleSearchOptionList.length >= 5
                                  ? true
                                  : false
                              ) {
                                e.stopPropagation();
                              }
                            },
                            readOnly:
                              jobTitleSearchOptionList.length >= 5
                                ? true
                                : false,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className="">Location</Typography>
                    <PlacesAutocomplete
                      value={autoCompleteSuggesions}
                      onChange={(ev: any) => {
                        setautoCompleteSuggesions(ev);
                      }}
                      searchOptions={{ types: ["(cities)"] }}
                    >
                      {renderPreferredGeographies}
                    </PlacesAutocomplete>
                  </Grid>
                  <Grid item xs={1} md={1} pl={1}>
                    <Typography className="">Radius</Typography>
                    <TextField
                      fullWidth
                      id="searchRadius"
                      name="searchRadius"
                      type="number"
                      value={formik.values.searchRadius}
                      onChange={formik.handleChange}
                      inputProps={{ style: { paddingTop: 2 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography className="">Work Authorization</Typography>
                    <Autocomplete
                      className="findjobs-chips"
                      multiple
                      id="tags-standard"
                      options={jobWorkAuthorizationList?.map(
                        (option: any) => option?.workAuthorization
                      )}
                      getOptionLabel={(option: any) => option}
                      value={formik.values?.workAuthorizations}
                      onChange={(_, newValue) => {
                        formik.setFieldValue("workAuthorizations", newValue);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option: any, index: any) => (
                          <Chip
                            color="primary"
                            size="small"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          variant="standard"
                          data-testid={"autocomplete-workauthorization"}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography className="">Work Experience</Typography>

                    <Autocomplete
                      className="findjobs-chips"
                      id="tags-standard"
                      disableClearable={true}
                      options={jobWorkExperiencesList.map((x: any) => x.id)}
                      getOptionLabel={(option: any) =>
                        getExperienceLabel(option)
                      }
                      value={formik.values?.experienceId}
                      onChange={(_, newValue: any) => {
                        if (newValue) {
                          let value = jobWorkExperiencesList?.find(
                            (item: any) => item.id === newValue
                          );
                          formik.setFieldValue(
                            "workExp",
                            value?.experienceValue
                          );
                          formik.setFieldValue("experienceId", newValue);
                          formik.setFieldValue(
                            "experienceText",
                            value?.experience
                          );
                        } else {
                          formik.setFieldValue("workExp", "");
                          formik.setFieldValue("experienceId", "");
                          formik.setFieldValue("experienceText", "");
                        }
                      }}
                      onBlur={formik.handleBlur}
                      renderTags={(value, getTagProps) =>
                        value.map((option: any, index: any) => (
                          <Chip
                            color="primary"
                            size="small"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          variant="standard"
                          data-testid={"autocomplete-workexperience"}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="">Industries</Typography>
                    <IndustryAutocomplete
                      classes="findjobs-chips"
                      industries={formik.values.industries}
                      onChange={handleIndustryChange}
                      isRequired={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="">Pay Rate</Typography>
                    <Grid container maxWidth={"xl"}>
                      <Grid item xs={3} md={3.7} pr={1}>
                        <TextField
                          fullWidth
                          id="minimumPayRate"
                          name="minimumPayRate"
                          label="Minimum"
                          type="number"
                          value={formik.values.minimumPayRate}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={3} md={3.7} pr={1}>
                        <TextField
                          fullWidth
                          id="maximumPayRate"
                          name="maximumPayRate"
                          label="Maximum"
                          type="number"
                          value={formik.values.maximumPayRate}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        md={2.2}
                        className="custom-currency-box"
                      >
                        <FormControl
                          fullWidth
                          variant="standard"
                          sx={{ minWidth: 80 }}
                          className="CurrencyControl"
                        >
                          <InputLabel id="payrateCurrency">Currency</InputLabel>
                          <Select
                            labelId="payrateCurrency"
                            id="payrateCurrency"
                            name="currency"
                            label="Currency"
                            value={formik.values.currency}
                            onChange={formik.handleChange}
                            className="CurrencySelect"
                          >
                            {masterCurrercyList?.map(
                              (item: any, key: number) => {
                                return (
                                  <MenuItem
                                    key={item?.currencyCode ?? key}
                                    value={item.currencyCode}
                                  >
                                    {item.currencyCode}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} md={2} pl={1}>
                        <TextField
                          fullWidth
                          id="n"
                          name="n"
                          label="Results"
                          type="number"
                          value={formik.values.n}
                          onChange={formik.handleChange}
                          error={formik.touched.n && Boolean(formik.errors.n)}
                          helperText={formik.touched.n && formik.errors.n}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="">Working location </Typography>

                    <RadioGroup
                      row
                      name="remote"
                      id="remote"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values?.remote}
                    >
                      {" "}
                      <FormControlLabel
                        value={"y"}
                        control={<Radio />}
                        label="Remote"
                      />
                      <FormControlLabel
                        value={"n"}
                        control={<Radio />}
                        label="Onsite"
                      />
                      {formik.values?.remote === "n" ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="allowRelocation"
                              id="allowRelocation"
                              value={"y"}
                              onChange={formik.handleChange}
                              size="small"
                            />
                          }
                          label="Allow Relocation"
                        />
                      ) : (
                        ""
                      )}
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid container>
                      <Grid item xs={12} md={12} mb={1}>
                        <SectionHeading title="Modify the search results" />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">
                          Skill
                        </Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          onChange={(e: any) => {
                            handleSliderChange("skills_pct", e.target.value);
                          }}
                          name="skills_pct"
                          valueLabelDisplay="auto"
                          value={sliderValues.skills_pct}
                          data-testid={"skill-slider"}
                        />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">
                          Title
                        </Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          name="job_title_pct"
                          onChange={(e: any) => {
                            handleSliderChange("job_title_pct", e.target.value);
                          }}
                          valueLabelDisplay="auto"
                          value={sliderValues.job_title_pct}
                          data-testid={"job_title_pct-slider"}
                        />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">
                          Technology
                        </Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          name="technology_pct"
                          onChange={(e: any) => {
                            handleSliderChange(
                              "technology_pct",
                              e.target.value
                            );
                          }}
                          valueLabelDisplay="auto"
                          value={sliderValues.technology_pct}
                          data-testid={"technology_pct-slider"}
                        />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">
                          Tools
                        </Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          name="tools_pct"
                          onChange={(e: any) => {
                            handleSliderChange("tools_pct", e.target.value);
                          }}
                          valueLabelDisplay="auto"
                          value={sliderValues.tools_pct}
                          data-testid={"tools_pct-slider"}
                        />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">
                          Education
                        </Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          name="education_pct"
                          onChange={(e: any) => {
                            handleSliderChange("education_pct", e.target.value);
                          }}
                          valueLabelDisplay="auto"
                          value={sliderValues.education_pct}
                          data-testid={"education_pct-slider"}
                        />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">
                          Certification
                        </Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          name="certification_pct"
                          onChange={(e: any) => {
                            handleSliderChange(
                              "certification_pct",
                              e.target.value
                            );
                          }}
                          valueLabelDisplay="auto"
                          value={sliderValues.certification_pct}
                          data-testid={"certification_pct-slider"}
                        />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">
                          Industry
                        </Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          name="industry_pct"
                          onChange={(e: any) => {
                            handleSliderChange("industry_pct", e.target.value);
                          }}
                          valueLabelDisplay="auto"
                          value={sliderValues.industry_pct}
                          data-testid={"industry_pct-slider"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                  <Box className="text-Align-Right">
                    <Button
                      type="button"
                      size="medium"
                      style={{
                        fontSize: "0.875rem",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                      variant="outlined"
                      className="abel button-large"
                      onClick={(e) => {
                        handleReset();
                      }}
                      disabled={isFormSubmitting}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      style={{
                        fontSize: "0.875rem",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                      variant="contained"
                      className="abel button-large"
                      onClick={(e) => {
                        formik.handleSubmit();
                      }}
                      disabled={isFormSubmitting}
                      endIcon={
                        isFormSubmitting ? (
                          <CircularProgress size={"16px"} color={"inherit"} />
                        ) : (
                          ""
                        )
                      }
                    >
                      Search
                    </Button>
                  </Box>
                </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {!isFormSubmitting && recommendedJobDataList  && (
                <RecommendedJobsSearchRow
                  totalRecords={totalRecords}
                  hasMoreData={hasMoreData}
                  loading={loading}
                  recommendationData={recommendedJobDataList ?? []}
                  sort={sort}
                  handleColumnSort={onSort}
                  onViewScoreCard={(selectedItem: any) => handleViewScoreCard(selectedItem)}
                  scorecardPopup={scorecardPopup}
                />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                setShowDialog={setShowDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <RecommendJobScorecardDialog
        handleOpenClose={() => {
          setScorecardPopup({ ...scorecardPopup, display: false });
        }}
        open={scorecardPopup?.display??false}
        score={scorecardPopup?.jobData}
        scoreResumeStatus={scoreResumeStatus}
      ></RecommendJobScorecardDialog>
    </>
  );
};

const isValidFileExtention = (fileName: string) => {
  let fileExt = fileName.split(".").pop();
  let validExt = ["txt", "rtf", "doc", "docx", "pdf"];
  return validExt.some((item) => item === fileExt);
};

const UserValidtionSchema = Yup.object().shape({
  resume: Yup.string().required("Please attach the candidate resume"),
  domains: Yup.array().min(1, "Please select domain"),
  n: Yup.number()
    .max(100, "Result must be smaller or equal to 100")
    .min(1, "Result must be greater than 0")
    .required("Result is required"),
});

export default FindJob;

const jobFilter = {
  pageIndex: 1,
  pageSize: 10,
  sortColumn: "score",
  sortOrder: 0,
  isSorted: true,
  jobIds: [],
  totalRecord: 0,
  searchKeywords: "",
  domains: [],
  showFeaturedJobs: false,
  showDisabledJobs: false,
  showRemoteJobs: false,
  advanceSearch: {
    jobTitle: [],
    location: [],
    client: [],
    minimumPayRate: 0,
    maximumPayRate: 0,
    currency: "",
  },
  recruiterStatus: "",
};
